<template>
     <div class="overlay-panel rel">
        <div class="panel-header p24 pb0">
            <div class="mini-logo"></div>
            <h2 class="panel-title nomargin bebas">Hai dimenticato la Password?</h2>
        </div>
        <form 
          class="forgotpass" 
          @submit.prevent="forgotPassword"
        > 
            <div class="flex-container flex-col pl24 pr24">
              <p>Inserisci l'indirizzo email con cui ti sei registrato.</p>
              <div class="flex-container">
                  <label class="flex-container flex-col">
                      <div class="flex-container heading-and-error">
                        <h3 class="bebas nomargin">Email</h3>
                        <ErrorMessage :validationStatus="vuel.uemail" />
                      </div>
                      
                      <input 
                          required 
                          v-model="uemail" 
                          type="email" 
                          placeholder="inserisci la tua email"
                          @blur="handleBlur('uemail')"
                          class="noborder nopadding pl8"
                      />
                      
                    
                  </label>
              </div>
              <p>
                A questo indirizzo verrà inviato un link 
                seguendo il quale sarà possibile scegliere 
                una nuova password.
              </p>
            </div>
            
            
            <div class="abs flex-container forgot-buttons ">
              <button 
                class="w100 noborder nopadding bebas reset" 
                @click="undo"
              > 
                Indietro
              </button>
              <input 
                :disabled="vuel.$invalid" 
                type="submit" 
                value="Submit" 
                class="w100 noborder nopadding bebas"
              >
            </div>
        </form>
    </div> 

</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { MESSAGE } from '@/store/VuexPaths'
import ErrorMessage from "./../formelements/ErrorMessage";

export default {
  components:{
    ErrorMessage
  },
  setup (){
    const store = useStore()
    const router = useRouter()

    const uemail = ref(null);

    // Validation Logics
    const rules = {
      uemail: { required, email },
    };
    const vuel = useVuelidate(
      rules,
      { uemail }
    );
    const handleBlur = (key) =>{
      vuel.value[key].$dirty = true;
    };

    const forgotPassword = async () => {
      
      vuel.$dirty = true;
      if (!vuel.$error) {
        try {
          await store.dispatch('auth/resetPassword', { email:uemail.value })
          await store.dispatch(MESSAGE, { title:'Richiesta nuova password', message:'controlla la tua email'})
        } catch(err) {
          const msg = err.message === 'Request failed with status code 404' ? 'Email non trovata' :err.message
          await store.dispatch(MESSAGE, { title:'errore nela modifica', message:msg })
          
        }
      }
      
    }

    const undo = async () =>{
      try {
          router.push('/login')
        } catch(err) {
          console.log(err.message)
          console.log(err)
        }
    }

    return{
      uemail,
      vuel,
      handleBlur,
      forgotPassword,
      undo
    }
  }
}
</script>
<style lang="postcss">
  .forgot-buttons{
    bottom: 0;
    width: 100%;
  }
</style>